import {onMounted, reactive,ref,computed} from "vue"
import {useStore} from "vuex"
import $api from "@/api"
import downloadFile from "@/utils/file/downLoadFile"
import {getImgUrl} from "@/utils/imageRelated"
import dialog from "@/utils/ui/dialog"

export default function useBiddingList(queryParams,showExportBtn){
    const store=useStore()
    const isLogin=computed(()=>store.state.userState.token)
    const nothing=ref(false)
    const biddingData=reactive({
        list:[],
        totalCount:0
    })
    const getBiddingList=async(isSearch=false)=>{
        if(isSearch){
        queryParams['HospitalName']=queryParams.Title
        }
        const [err,data]=await $api.bidding.getBiddingList(queryParams)
        if(!data.succeeded || !data.data.items.length) {
            nothing.value=true
            biddingData.list=[]
            return;
        };
        nothing.value=false
        
        biddingData.list=data.data.items
        biddingData.totalCount=data.data.totalCount
        showExportBtn.value=isSearch
    }
    const exportExcel=async()=>{
        if(!isLogin.value){
            store.commit("toggleLoginForm",true)
            return;
        }
        const {Areas,ProjectType,startTime}=queryParams
        const [err,data]=await $api.bidding.getExcelUrl(Object.assign(queryParams,{connectionId:globalThis.connectionId}))
        if(!data.succeeded) {
            dialog.message("error","出现了一些问题,请稍后再试吧~")
            return;
        };
        if(data.data?.images){
            store.commit("setOrderInfo",{title:`${ProjectType||'招投标'}-${Areas||'列表'}-${startTime||'导出'}`,id:"",price:data.data.money,type:"",outerQrcode:data.data.images})
            store.commit("toggleOrdinaryForm",true)
        }else{
        downloadFile(getImgUrl(data.data.excel),`${ProjectType||'招投标'}-${Areas||'列表'}-${startTime||'导出'}`)
        }
    }
    onMounted(getBiddingList)
    return {
        nothing,
        biddingData,
        getBiddingList,
        exportExcel
    }
}