<template>
    <el-empty :image-size="200" description="暂无数据"></el-empty>
</template>
<script>
import { defineComponent } from 'vue'
import {ElEmpty} from "element-plus"
export default defineComponent({
    name:"NoData",
    components:{
        [ElEmpty.name]:ElEmpty
    },
    setup() {
        
    },
})
</script>
<style lang="less" scoped>

</style>
