<template>
    <div class="inner_banner"><a class="db" href="jvascript:void(0);"><img :src="require('@/assets/images/bidding_banner.jpg')" /></a></div>
    <main class="ztb_list">
     
      <el-form :model="searchForm"  class="search-form" >
      <div class="ztb_search">
          <el-form-item >
            <el-select v-model="searchForm.ProjectType" placeholder="类型">
              <el-option label="招标" value="招标"></el-option>
              <el-option label="中标" value="中标"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="province">
            <el-select v-model="searchForm.Areas" placeholder="省份">
              <el-option v-for="item in provinces" :key="item.adcode" :label="item.name" :value="item.name"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="name">
            <el-input v-model="searchForm.Title" placeholder="项目名称/医院名称"></el-input>
          </el-form-item>

          <el-form-item prop="date">
            <el-date-picker
            size="large"
              v-model="searchForm.date"
              type="daterange"
               format="YYYY年MM月DD日"
                value-format="YYYY/MM/DD"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @calendar-change="getRangTime">
            </el-date-picker>
          </el-form-item>
        
      </div>
      <el-form-item class="submit_box">
        <div class="button_box">
          <el-button  type="primary" @click="getBiddingList(true)">
            <i class="iconfont icon-search-copy"></i>
            <span>搜索</span>
          </el-button>

          <el-button @click="reset()">
            <i class="iconfont icon-search-copy"></i>
            <span>重置</span>
          </el-button>
        </div>
        <div class="export" v-show="showExportBtn" @click="exportExcel()">
          导出详细信息
        </div>
        </el-form-item>
      </el-form>
      <div>
          <ul >
            <li v-for="item in biddingData.list" class="bidding-item"><a class="db" @click="showDetail(store,router,'bidding','BiddingDetail',item)"><span class="w80p dib otw">{{item.title}}</span><div class="dib w10p"><span class="price">{{item.price}}</span><span class="f12">万元</span></div><span>{{item.createdTime.substring(0,10)}}</span></a></li>
          </ul>
           <div class="pagnation" v-if="!nothing">
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="searchForm.PageIndex"
            :page-sizes="[5,10,15,20]"
            :page-size="searchForm.PageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="biddingData.totalCount">
          </el-pagination>
        </div>
          <no-data v-if="nothing"></no-data>
      </div>
    </main>
</template>
<script setup>
import { ref,defineComponent } from 'vue'
import {useRouter} from "vue-router"
import {useStore} from "vuex"
import useSearchForm from "@/composable/bidding/useSearchForm"
import useBiddingList from "@/composable/bidding/useBiddingList"
import usePagination from "@/composable/universal/usePagination"
import NoData from "@/components/nothing/noData"
import showDetail from "@/composable/universal/showDetail"
        defineComponent({
          NoData
        })
        const router=useRouter()
        const store=useStore()
        const showExportBtn=ref(false)
        const {searchForm,provinces}=useSearchForm()
        const {nothing,biddingData,getBiddingList,exportExcel}=useBiddingList(searchForm,showExportBtn)
        const {handleSizeChange,handleCurrentChange}=usePagination(searchForm,getBiddingList)
        const getRangTime=([start,end])=>{
            if(end){
              [searchForm.startTime,searchForm.endTime]=[new Date(start).format('yyyy-MM-dd'),new Date(end).format('yyyy-MM-dd')];
            }
        }
        const reset=()=>{
            searchForm['ProjectType']=""
            searchForm['Areas']=""
            searchForm['Title']=""
            searchForm['date']=""
            searchForm['startTime']=""
            searchForm['endTime']=""
            getBiddingList()
        }
</script>
<style lang="less" scoped>
:deep(.el-pager),:deep(.el-pager>li){
    width: auto !important;
}
.bidding-item{
  cursor: pointer;
}
.bidding-item:hover{
    background-color: #a4d0f973;
    color: white;
}
.pagnation{
    text-align: center;
}

.price{color:#FF6501;font-weight: bold;}

</style>